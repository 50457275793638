import { Component, Output, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Md5 } from 'md5-typescript';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NullViewportScroller } from '@angular/common/src/viewport_scroller';


@Component({
  selector: 'app-root',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
  title = '';

  log(t){

    console.log(t);

  }

  public options: Object = {
    placeholderText: 'Описание',
    charCounterCount: false,
    language: 'ru',
    toolbarButtons: ['bold', 'italic', 'underline', 'insertImage', 'insertLink', 'insertFile', 'insertVideo'],

    htmlAllowedAttrs: ['accept', 'accept-charset', 'accesskey', 'action', 'align', 'allowfullscreen', 'allowtransparency', 'alt', 'aria-.*', 'async', 'autocomplete', 'autofocus', 'autoplay', 'autosave', 'background', 'bgcolor', 'border', 'charset', 'cellpadding', 'cellspacing', 'checked', 'cite', 'class', 'color', 'cols', 'colspan', 'content', 'contenteditable', 'contextmenu', 'controls', 'coords', 'data', 'data-.*', 'datetime', 'default', 'defer', 'dir', 'dirname', 'disabled', 'download', 'draggable', 'dropzone', 'enctype', 'for', 'form', 'formaction', 'frameborder', 'headers', 'height', 'hidden', 'high', 'href', 'hreflang', 'http-equiv', 'icon', 'id', 'ismap', 'itemprop', 'keytype', 'kind', 'label', 'lang', 'language', 'list', 'loop', 'low', 'max', 'maxlength', 'media', 'method', 'min', 'mozallowfullscreen', 'multiple', 'muted', 'name', 'novalidate', 'open', 'optimum', 'pattern', 'ping', 'placeholder', 'playsinline', 'poster', 'preload', 'pubdate', 'radiogroup', 'readonly', 'rel', 'required', 'reversed', 'rows', 'rowspan', 'sandbox', 'scope', 'scoped', 'scrolling', 'seamless', 'selected', 'shape', 'size', 'sizes', 'span', 'src', 'srcdoc', 'srclang', 'srcset', 'start', 'step', 'summary', 'spellcheck', 'style', 'tabindex', 'target', 'title', 'type', 'translate', 'usemap', 'value', 'valign', 'webkitallowfullscreen', 'width', 'wrap', 'routerLink'],
    fontSize: ['12', '13', '14', '16', '18', '20'],


    // Set the image upload parameter.
    imageUploadParam: 'file_param',

    imageUploadURL: 'http://kb.etsgroup.ru/upload.php',

    imageUploadParams: {
      id: 'my_editor'
    },

    imageUploadMethod: 'POST',

    imageMaxSize: 10 * 1024 * 1024,

    imageOutputSize: true,

    imagePasteProcess: true,

    fileUploadParam: 'file_param',

    // Set the file upload URL.
    fileUploadURL: 'http://kb.etsgroup.ru/upload.php',

    // Additional upload params.
    fileUploadParams: { id: 'my_editor' },

    // Set request type.
    fileUploadMethod: 'POST',

    // Set max file size to 20MB.
    fileMaxSize: 100 * 1024 * 1024,

    // Allow to upload any file.
    fileAllowedTypes: ['*'],

    imageDefaultWidth: 300,

    videoAllowedTypes: ['mp4', 'webm', 'ogg'],

    videoMaxSize: 1024 * 1024 * 100,

    videoUploadMethod: 'POST',

    videoUploadParam: 'file_param',

    videoUploadParams: {
      id: 'my_editor'
    },

    videoUploadURL: 'http://kb.etsgroup.ru/upload.php',

    // Allow to upload PNG and JPG.
    imageAllowedTypes: ['jpeg', 'jpg', 'png'],





  };

  public commentEditor: Object = {
    placeholderText: 'Комментарий',
    charCounterCount: false,
    language: 'ru',
    toolbarButtons: ['bold', 'italic', 'underline', 'insertImage', 'insertLink', 'insertFile', 'insertVideo'],

    htmlAllowedAttrs: ['accept', 'accept-charset', 'accesskey', 'action', 'align', 'allowfullscreen', 'allowtransparency', 'alt', 'aria-.*', 'async', 'autocomplete', 'autofocus', 'autoplay', 'autosave', 'background', 'bgcolor', 'border', 'charset', 'cellpadding', 'cellspacing', 'checked', 'cite', 'class', 'color', 'cols', 'colspan', 'content', 'contenteditable', 'contextmenu', 'controls', 'coords', 'data', 'data-.*', 'datetime', 'default', 'defer', 'dir', 'dirname', 'disabled', 'download', 'draggable', 'dropzone', 'enctype', 'for', 'form', 'formaction', 'frameborder', 'headers', 'height', 'hidden', 'high', 'href', 'hreflang', 'http-equiv', 'icon', 'id', 'ismap', 'itemprop', 'keytype', 'kind', 'label', 'lang', 'language', 'list', 'loop', 'low', 'max', 'maxlength', 'media', 'method', 'min', 'mozallowfullscreen', 'multiple', 'muted', 'name', 'novalidate', 'open', 'optimum', 'pattern', 'ping', 'placeholder', 'playsinline', 'poster', 'preload', 'pubdate', 'radiogroup', 'readonly', 'rel', 'required', 'reversed', 'rows', 'rowspan', 'sandbox', 'scope', 'scoped', 'scrolling', 'seamless', 'selected', 'shape', 'size', 'sizes', 'span', 'src', 'srcdoc', 'srclang', 'srcset', 'start', 'step', 'summary', 'spellcheck', 'style', 'tabindex', 'target', 'title', 'type', 'translate', 'usemap', 'value', 'valign', 'webkitallowfullscreen', 'width', 'wrap', 'routerLink'],
    fontSize: ['12', '13', '14', '16', '18', '20'],


    // Set the image upload parameter.
    imageUploadParam: 'file_param',

    imageUploadURL: 'http://kb.etsgroup.ru/upload.php',

    imageUploadParams: {
      id: 'my_editor'
    },

    imageUploadMethod: 'POST',

    imageMaxSize: 10 * 1024 * 1024,

    imageOutputSize: true,

    imagePasteProcess: true,

    fileUploadParam: 'file_param',

    // Set the file upload URL.
    fileUploadURL: 'http://kb.etsgroup.ru/upload.php',

    // Additional upload params.
    fileUploadParams: { id: 'my_editor' },

    // Set request type.
    fileUploadMethod: 'POST',

    // Set max file size to 20MB.
    fileMaxSize: 100 * 1024 * 1024,

    // Allow to upload any file.
    fileAllowedTypes: ['*'],

    imageDefaultWidth: 300,

    videoAllowedTypes: ['mp4', 'webm', 'ogg'],

    videoMaxSize: 1024 * 1024 * 100,

    videoUploadMethod: 'POST',

    videoUploadParam: 'file_param',

    videoUploadParams: {
      id: 'my_editor'
    },

    videoUploadURL: 'http://kb.etsgroup.ru/upload.php',

    // Allow to upload PNG and JPG.
    imageAllowedTypes: ['jpeg', 'jpg', 'png'],





  };

  calendarOptions = {
    selectPeriodEnabled: false,
    suggestions: false,
    disabledBefore: true,
    weekStart: 1,
    lang: 'ru',
    time: {
      enabled: true
    },
    submitMode: true
  };

  dates = {
    dateStart: null
  };
  calendarVisible = false;
  textareaFocused;
  accessToken;
  content = '';
  secretKey;
  text = null;
  userPanel = false;
  clientsSearch = null;
  prioricity = 1;
  deadline = '23.06.2019 13:15';
  clients = [];
  users = [];
  userList = null;
  usersSearch = null;
  forEveryClient = false;
  editMode = null;
  taskId;
  task;
  childTasks;
  isGroup;
  isChild;
  groupId;
  entities;
  owner;
  dateCreate;
  myId;
  comments;
  calls;
  dateClose;
  closed = false;
  me;
  history;
  textComment : string;
  entity;
  entityTypeId;
  openClient;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
  ) {
    this.accessToken = this.route.snapshot.queryParams.accessToken;
    this.secretKey = this.route.snapshot.queryParams.secretKey;
    this.editMode = this.route.snapshot.queryParams.editMode;
    this.taskId = this.route.snapshot.queryParams.taskId;
    this.entities = this.route.snapshot.queryParams.entities;
    this.editMode = (this.editMode === "false") ? false : true;
    this.getUsers();
    //this.getMe();
  }

  clickDateUntil() {
    this.calendarVisible = true;
  }

  showComment(event){

    this.textareaFocused = true;

  }

  cancelComment(event){

    this.textareaFocused = false;
    this.textComment = null;

  }

  changeDeadline(deadline) {
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
    this.http.request<any>('POST', 'http://siteapi.etsgroup.ru/task/changeDeadline',
      {
        body: this.getPostBody({
          deadline: this.formatDate(deadline),
          taskId: this.taskId
        }),
        headers: httpHeaders
      }
    ).subscribe(data => {

    });
  }
  formatDate(date) {

    let dd = date.getDate();
    if (dd < 10) { dd = '0' + dd; }

    let mm = date.getMonth() + 1;
    if (mm < 10) { mm = '0' + mm; }

    return  date.getFullYear() + '-' + mm + '-' + dd + ' ' + date.getHours() + ':' + date.getMinutes();
  }


  onChangedCalendar2(data) {
    console.log('onChangedCalendar2', data);
    this.dates.dateStart = new Date(data.dateStart);
    this.changeDeadline(this.dates.dateStart);
    this.calendarVisible = false;
    this.postComment('>> Изменил дату выполнить до на ' + this.dates.dateStart.getDate() + '.' + (this.dates.dateStart.getMonth() + 1) + '.' + this.dates.dateStart.getFullYear() + ' ' + this.dates.dateStart.getHours() + ':' + this.dates.dateStart.getMinutes())
  }
  onChangedCalendar(data) {
    console.log('onChangedCalendar', data);
    //this.dates = null;
    this.dates.dateStart = new Date(data.dateStart);
    this.calendarVisible = false;
  }

  sendParent(id, type) {
    let parent = window.parent;
    parent.postMessage(JSON.stringify({ id, type }), '*');
  }

  getUser(id) {
    let a = null;
    for (let user of this.userList) {
      if (user.id == id) {
        a = user
      }
    }
    return a;
  }

  save() {
    const params = {
      taskId: this.taskId,
      clients: this.clients,
      users: this.users,
      forEveryClient: this.forEveryClient,
      content: this.content.replace('<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>', ''),
      title: this.title,
      prioricity: this.prioricity,
      deadline: (this.dates.dateStart) ? this.dates.dateStart.getFullYear() + '-' + (this.dates.dateStart.getMonth() + 1) + '-' + this.dates.dateStart.getDate() + ' ' + this.dates.dateStart.getHours() + ':' + this.dates.dateStart.getMinutes() : null,
      isGroup: this.isGroup
    };
    if ((!this.users || (this.users && this.users.length == 0)) && !this.forEveryClient) {
      alert('Укажите ответственного');
      return;
    }
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
    this.http.request<any>('POST', 'http://siteapi.etsgroup.ru/task/save',
      {
        body: this.getPostBody(params),
        headers: httpHeaders
      }
    ).subscribe(data => {
      this.router.navigate(['/'], { replaceUrl: true, queryParams: { accessToken: this.accessToken, secretKey: this.secretKey, editMode: false, taskId: data.response.data.taskId } });
      setTimeout(function () { location.reload() }, 1000)
    });

  }

  openTask(taskId) {
    this.router.navigate(['/'], { replaceUrl: true, queryParams: { accessToken: this.accessToken, secretKey: this.secretKey, editMode: false, taskId: taskId } });
  }

  changeUser(userId) {
    this.http.request<any>('POST', 'http://siteapi.etsgroup.ru/task/changeUser', {
      params: this.getParams({
        userId,
        taskId: this.taskId
      })
    }).subscribe(data => {

    });
  }

  getPostBody(params) {
    const signature = Md5.init(JSON.stringify(params) + this.secretKey);
    console.log(JSON.stringify(params));
    return {
      accessToken: this.accessToken,
      signature,
      params: params
    };
  }
  getParamsEncoded(params) {
    const signature = Md5.init(JSON.stringify(params) + this.secretKey);
    return {
      accessToken: this.accessToken,
      signature,
      params: encodeURIComponent(JSON.stringify(params))
    };
  }

  getParams(params) {
    const signature = Md5.init(JSON.stringify(params) + this.secretKey);
    return {
      accessToken: this.accessToken,
      signature,
      params: JSON.stringify(params)
    };
  }

  closeTask() {
    if (confirm('Завершить задачу?')) {
      this.http.request<any>('POST', 'http://siteapi.etsgroup.ru/task/closeTask', {
        params: this.getParams({
          taskId: this.taskId
        })
      }).subscribe(data => {
        this.getTask()
      });
    }
  }

  forEveryClientChange(val) {
    //console.log(val);
    (val === true) ? this.forEveryClient = true : this.forEveryClient = false;
  }

  setIsGroup() {
    if ((this.clients && this.clients.length > 0) || (this.users && this.users.length > 1)) {
      this.isGroup = true;
    } else {
      this.isGroup = false;
    }
    console.log(this.isGroup);
  }

  addClient(client) {
    this.clients.push(client);
    this.clientsSearch = null;
    this.setIsGroup()
  }
  addUser(user) {
    this.users = [];
    this.users.push(user);
    this.usersSearch = null;
    this.setIsGroup();

    if (this.taskId) {
      this.changeUser(user.id)
    }

  }
  findUser(text) {

    const a = [];

    if (text.length > 1) {
      // tslint:disable-next-line: forin
      for (const i in this.userList) {
        const user = this.userList[i];
        const str = user.name + ' ' + user.lastName;
        //str;
        if (str.toLowerCase().includes(text.toLowerCase()) && user.active) {
          a.push(user);
        }
      }
    }
    this.usersSearch = a;
  }

  postComment(event) {

    
    // console.log(this.textComment);
    

    const httpHeaders = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
    this.http.request<any>('POST', 'http://siteapi.etsgroup.ru/task/postComment',
      {
        body: this.getPostBody({
          textComment: this.textComment,
          userId: this.me.id,
          taskId: this.taskId
        }),
        headers: httpHeaders
      }
    ).subscribe(data => {
      setTimeout(function () { location.reload() }, 1000);
    });
  }

  getUsers() {
    this.http.request<any>('POST', 'http://siteapi.etsgroup.ru/common/getUsers', {
      params: this.getParams(null)
    }).subscribe(data => {
      this.userList = data.response.data;
      this.getMe()
    }, error => {
      console.log(error.error.response.errorMessage);
    });
  }

  getMe() {
    this.http.request<any>('POST', 'http://siteapi.etsgroup.ru/common/getMyId', {
      params: this.getParams(null)
    }).subscribe(data => {
      this.myId = data.response.data;
      if (!this.taskId) {
        for (let user of this.userList) {
          if (user.id == this.myId) {
            this.owner = user
          }
        }

      } else {
        for (let user of this.userList) {
          if (user.id == this.myId) {
            this.me = user
          }
        }
      }

    }, error => {
      console.log(error.error.response.errorMessage);
    });
  }


  excludeExistUser(data) {
    const included = [];
    const excluded = [];
    // tslint:disable-next-line: forin
    for (const k in data) {
      const item = data[k];
      for (const client of this.users) {
        (client.id === item.id) ? excluded.push(k) : null;
      }
    }

    // tslint:disable-next-line: forin
    for (const k in data) {
      const item = data[k];
      (!excluded.includes(k)) ? included.push(item) : null;
    }
    return included;

  }

  excludeExist(data) {
    const included = [];
    const excluded = [];
    // tslint:disable-next-line: forin
    for (const k in data) {
      const item = data[k];
      for (const client of this.clients) {
        (client.id === item.id && client.entityTypeId === item.entityTypeId) ? excluded.push(k) : null;
      }
    }

    // tslint:disable-next-line: forin
    for (const k in data) {
      const item = data[k];
      (!excluded.includes(k)) ? included.push(item) : null;
    }
    return included;

  }

  sortByDate(a, b) {
    if (a.dateCreate.date < b.dateCreate.date) {
      return -1;
    }
    if (a.dateCreate.date > b.dateCreate.date) {
      return 1;
    }
    return 0;
  }


  getTask() {
    this.http.request<any>('POST', 'http://siteapi.etsgroup.ru/task/getTask', {
      params: this.getParams({ taskId: this.taskId })
    }).subscribe(data => {
      this.task = data.response.data;
      this.title = this.task.title;
      this.comments = this.task.comments;
      this.calls = this.task.calls;
      this.content = this.task.content;
      this.forEveryClient = this.task.forEveryClient;
      this.childTasks = this.task.childTasks;
      this.isGroup = this.task.isGroup;
      this.isChild = this.task.isChild;
      this.entity = this.task.entity;
      this.entityTypeId = this.task.entityTypeId;
      this.groupId = this.task.groupId;
      this.dateCreate = new Date(this.task.dateCreate.date);
      this.dateClose = (this.task.dateClose) ? new Date(this.task.dateClose.date) : null;
      this.closed = (this.task.dateClose) ? true : false;
      this.dates.dateStart = (this.task.deadline) ? new Date(this.task.deadline.date) : null;
      this.users = [];
      this.history = [];

      if (this.comments) {
        for (let comment of this.comments) {
          comment.type = 'comment'
          this.history.push(comment);
        }
      }
      if (this.calls) {
        for (let call of this.calls) {
          call.type = 'call'
          this.history.push(call);
        }
      }
      console.log(this.history)

      this.history.sort(this.sortByDate);

      for (let user of this.userList) {
        if (user.id == this.task.owner) {
          this.owner = user;
        }
      }

      if (this.isGroup && this.childTasks) {

        for (let i in this.childTasks) {
          let task = this.childTasks[i];
          for (let user of this.userList) {
            this.childTasks[i].closed = (task.dateClose) ? true : false;
            this.childTasks[i].dateCloseFormat = (task.dateClose) ? new Date(task.dateClose.date) : null;


            if (user.id == task.user) {
              this.childTasks[i].user = user
              this.users.push(user)
            }
          }
        }
      } else {
        for (let user of this.userList) {
          if (user.id == this.task.user) {
            this.users.push(user)
          }
        }

      }


    });
  }
  cancel() {
    this.router.navigate(['/'], { replaceUrl: true, queryParams: { accessToken: this.accessToken, secretKey: this.secretKey, editMode: false, taskId: this.taskId } });
  }
  edit() {
    this.router.navigate(['/'], { replaceUrl: true, queryParams: { accessToken: this.accessToken, secretKey: this.secretKey, editMode: true, taskId: this.taskId } });
  }

  find(text) {
    const params = {
      text
    };

    this.http.request<any>('POST', 'http://siteapi.etsgroup.ru/client/find', {
      params: this.getParams(params)
    }).subscribe(data => {
      console.log(data);

      this.clientsSearch = this.excludeExist(data.response.data);

    }, error => {
      console.log(error.error.response.errorMessage);
    });
  }


  findByIds() {
    const params = {
      entities: JSON.stringify(this.entities)
    };
    this.http.request<any>('POST', 'http://siteapi.etsgroup.ru/client/findByIds', {
      params: this.getParams(params)
    }).subscribe(data => {
      this.clients = data.response.data;
      this.setIsGroup()
    });

  }



  ngOnInit() {
    //this.dates.dateStart.setDate(this.dates.dateStart.getDate()+1)
    this.route.queryParams.subscribe(data => {
      //console.log(data);
      this.clients = [];
      this.taskId = data.taskId;
      this.editMode = (data.editMode === "false") ? false : true;
      this.entities = (data.entities) ? JSON.parse(data.entities) : null;
      //console.log(this.entities);

      if (this.entities && this.entities.length > 0) {
        this.findByIds();
      }

      if (this.taskId) {
        this.getTask();
      }

    });


  }
}

