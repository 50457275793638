import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/languages/ru.js';
import { FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import { HttpClientModule } from '@angular/common/http';
import { FormComponent } from './form/form.component';
import { FormsModule } from '@angular/forms';
import { ManagerComponent } from './manager/manager.component';
import { DatepickerModule  } from 'datepicker-ns';


@NgModule({
  declarations: [
    AppComponent,
    FormComponent,
    ManagerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    FormsModule,
    DatepickerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
