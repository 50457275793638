import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.css']
})
export class ManagerComponent implements OnInit {

  @Input() manager: any;
  constructor() { }

  ngOnInit() {
    console.log(this.manager)
  }

}
